import type { ComponentProps, VariantProps } from '@stitches/react'
import * as Colors from '@themes/Colors'
import Fonts from '@themes/Fonts'
import { styled, theme } from '@themes/stitches.config'
import * as React from 'react'
import type { PressableProps, StyleProp, TextStyle } from 'react-native'
import {
  ActivityIndicator,
  Platform,
  Pressable,
  StyleSheet,
  Text,
} from 'react-native'

const ButtonInternal = styled('button', {
  all: 'unset',
  borderWidth: 2,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',

  userSelect: 'none',
  cursor: 'pointer',

  textTransform: 'uppercase',

  fontFamily: '$bold',
  fontWeight: '700',

  textAlign: 'center',

  '&:disabled': {
    cursor: 'not-allowed',
  },

  '&:focus': {
    outline: '$sky4 4px solid',
    outlineOffset: -2,
  },

  variants: {
    format: {
      rectangular: {
        borderRadius: 4,
      },
      rounded: {
        borderRadius: 32,
      },
    },

    emphasis: {
      primary: {
        borderColor: '$sky10',
        color: '$white',

        backgroundColor: '#33cfff',

        '&:hover': {
          backgroundColor: '$sky10',
        },
        '&:active': {
          backgroundColor: '#33cfff',
        },
        '&:disabled': {
          backgroundColor: '$sky9',
        },
      },
      secondary: {
        backgroundColor: '$neutralGray',
        borderColor: '$neutralGray',
        color: '$alternateGray',
        '&:hover': {
          backgroundColor: '$sky10',
        },
        '&:active': {
          backgroundColor: '$primary',
        },
        '&:disabled': {
          backgroundColor: '$sky9',
        },
      },
      tertiary: {
        backgroundColor: '#33cfff',
        borderColor: '$primary',
        color: '$primary',
        '&:hover': {
          color: '#33cfff',
        },
        '&:active': {
          color: '#33cfff',
        },
        '&:disabled': {
          backgroundColor: '$sky9',
        },
      },
      green: {
        borderColor: '$sky10',
        color: '$white',

        backgroundColor: '$green',

        '&:hover': {
          backgroundColor: '#49da83',
        },
        '&:active': {
          backgroundColor: '$green',
        },

        '&:focus': {
          outline: '$slate4 4px solid',
          outlineOffset: -2,
        },
        '&:disabled': {
          color: '$slate10',
          backgroundColor: '$slate6',
        },
      },
      purple: {
        borderColor: '$sky10',
        color: '$white',

        backgroundColor: '#6633CC',

        '&:hover': {
          backgroundColor: '#7744DD',
        },
        '&:active': {
          backgroundColor: '#6633CC',
        },

        '&:focus': {
          outline: '$slate4 4px solid',
          outlineOffset: -2,
        },

        '&:disabled': {
          color: '$slate10',
          backgroundColor: '$slate6',
        },
      },
    },

    size: {
      small: {
        fontSize: '$small',
        letterSpacing: 1,
        paddingBlock: 10,
        paddingInline: 12,
        lineHeight: 1.5,
      },
      regular: {
        fontSize: '$regular',

        lineHeight: '20px',
        letterSpacing: 1,
        paddingBlock: 16,
        paddingInline: 24,
      },
    },

    fullWidth: {
      true: {
        width: '100%',
      },
    },

    fullFlex: {
      true: {
        flex: 1,
      },
    },

    spaced: {
      true: {
        letterSpacing: 1.75,
      },
    },
  },

  defaultVariants: {
    format: 'rectangular',
    emphasis: 'primary',
    size: 'regular',
    fullWidth: false,
    fullFlex: false,
  },
})

type ButtonProps = {
  title: string
  isBusy?: boolean
  disabled?: boolean
  as?: 'a' | 'button'
  padding?: {
    paddingTop?: number
    paddingBottom?: number
    paddingLeft?: number
    paddingRight?: number
  }
} & VariantProps<typeof ButtonInternal> &
  ComponentProps<typeof ButtonInternal>

export function Button(props: ButtonProps) {
  const { title, isBusy, disabled, padding, ...restProps } = props

  // Native button styles
  let nativeButtonStyle: PressableProps['style'] = [
    styles.baseButton,
    styles.nativeBaseButton,
  ]
  switch (restProps.emphasis) {
    case 'secondary':
      nativeButtonStyle = [...nativeButtonStyle, styles.secondaryButton]
      break
    case 'tertiary':
      nativeButtonStyle = [...nativeButtonStyle, styles.tertiaryButton]
      break
    case 'purple':
      nativeButtonStyle = [...nativeButtonStyle, styles.purpleButton]
      break
  }
  switch (restProps.format) {
    case 'rectangular':
      nativeButtonStyle = [...nativeButtonStyle, styles.rectangularButton]
      break
    case 'rounded':
      nativeButtonStyle = [...nativeButtonStyle, styles.roundedButton]
      break
  }
  switch (restProps.size) {
    case 'small':
      nativeButtonStyle = [...nativeButtonStyle, styles.smallButton]
      break
  }
  if (restProps.fullWidth)
    nativeButtonStyle = [...nativeButtonStyle, styles.fullWidth]
  if (restProps.fullFlex)
    nativeButtonStyle = [...nativeButtonStyle, styles.fullFlex]

  // Apply custom padding if provided
  if (padding) {
    const customPadding = {
      paddingTop:
        padding.paddingTop !== undefined ? padding.paddingTop : undefined,
      paddingBottom:
        padding.paddingBottom !== undefined ? padding.paddingBottom : undefined,
      paddingLeft:
        padding.paddingLeft !== undefined ? padding.paddingLeft : undefined,
      paddingRight:
        padding.paddingRight !== undefined ? padding.paddingRight : undefined,
    }
    nativeButtonStyle = [...nativeButtonStyle, customPadding]
  }

  // Native text styles
  let nativeTextStyle: StyleProp<TextStyle> = [styles.baseText]
  switch (restProps.emphasis) {
    case 'secondary':
      nativeTextStyle = [...nativeTextStyle, styles.secondaryText]
      break
    case 'tertiary':
      nativeTextStyle = [...nativeTextStyle, styles.tertiaryText]
      break
  }
  switch (restProps.size) {
    case 'small':
      nativeTextStyle = [...nativeTextStyle, styles.smallText]
      break
  }

  return (
    <>
      {Platform.OS === 'web' ? (
        <ButtonInternal
          aria-label={title}
          {...restProps}
          disabled={disabled}
          css={padding ? { ...padding } : undefined}
        >
          {isBusy ? (
            <ActivityIndicator
              size="small"
              color={theme.colors.altWhite.toString()}
            />
          ) : (
            title
          )}
        </ButtonInternal>
      ) : (
        <Pressable
          onPress={(e: any) => {
            if (restProps.onClick) restProps.onClick(e)
          }}
          style={nativeButtonStyle}
          disabled={disabled}
        >
          {isBusy ? (
            <ActivityIndicator
              size="small"
              color={theme.colors.altWhite.toString()}
            />
          ) : (
            <Text style={nativeTextStyle}>{title}</Text>
          )}
        </Pressable>
      )}
    </>
  )
}

const styles = StyleSheet.create({
  baseButton: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    userSelect: 'none',
    backgroundColor: Colors.primary,
    paddingHorizontal: 6,
    paddingVertical: 8,
    borderRadius: 4,
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 12,
    paddingRight: 12,
    borderWidth: 2,
    borderColor: Colors.primary,
  },
  baseText: {
    flex: 1,
    fontFamily: Fonts.type.alternateBold,
    fontWeight: '700',
    color: Colors.white,
    textTransform: 'uppercase',
    textAlign: 'center',
    fontSize: 15,
    letterSpacing: 1,
  },
  secondaryButton: {
    backgroundColor: Colors.textGray,
    borderColor: Colors.textGray,
  },
  tertiaryButton: {
    backgroundColor: Colors.transparent,
    borderColor: Colors.primary,
  },
  purpleButton: {
    backgroundColor: Colors.newPurple,
    borderColor: Colors.newPurple,
  },
  smallButton: {
    display: 'flex',
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 12,
    paddingRight: 12,
    lineHeight: 1.5,
  },
  nativeBaseButton: {
    paddingTop: 12,
    paddingBottom: 12,
  },
  smallText: {
    fontSize: 13,
  },
  secondaryText: {
    color: Colors.white,
  },
  tertiaryText: {
    color: Colors.primary,
  },
  purpleText: {
    color: Colors.white,
  },
  rectangularButton: {
    borderRadius: 4,
  },
  roundedButton: {
    borderRadius: 32,
  },
  fullWidth: {
    width: '100%',
  },
  fullFlex: {
    flex: 1,
  },
})
